import React, { useMemo, useState } from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";

import { PageLayout, SEO } from "@components/page-layout";
import { Products } from "@containers/products";
import {
  BlogArticleBrowseCategories,
  BlogArticleCard,
  BlogArticleCategories,
  BlogArticleCategory,
  BlogCategoryHeader,
  BlogSubscribe,
  FollowICash,
  Pagination,
} from "@brainfinance/icash-component-library";

import defaultImg from '../images/default.png';

const ARTICLES_PER_PAGE = 9;

const BlogSearch = (props: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const params = new URLSearchParams(props.location.search);
  const searchString = params.get("search");
  
  const { allContentfulBlogArticle, allCategories } = useStaticQuery(graphql`
    {
      allContentfulBlogArticle {
        pageInfo {
          totalCount
          pageCount
          itemCount
        }
        nodes {
          slug
          categories {
            slug
            name
          }
          publicationDate
          mainImage {
            file {
              url
            }
            description
          }
          h1
          author {
            slug
            name
            picture {
              file {
                url
              }
            }
          }
          article {
            raw
          }
          metaDescription
        }
      }
      allCategories: allContentfulBlogCategory {
        nodes {
          name
          slug
        }
      }
    }
  `);

  const filteredArticle = !!searchString?.trim() ? allContentfulBlogArticle.nodes.filter((article: any) => {
    return (
      article.h1.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) >= 0
      || article.article.raw.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) >= 0
    );
  }) : [];

  const headerTitle = useMemo(() => {
    let result = searchString?.trim().slice(0, 50) ?? '';
    if (searchString && searchString.trim().length > 50) {
      result += '...';
    } else if (!searchString?.trim()) {
      result = '« Search query with no result »';
    }

    return result;
  }, [searchString]);

  const currentPagedArticles = filteredArticle.slice((currentPage - 1) * ARTICLES_PER_PAGE).slice(0, ARTICLES_PER_PAGE);

  const articlesAfterBasis = useMemo(() => {
    return currentPagedArticles.length % 3 === 1
    ? 'after:basis-[calc(66.666667%_-_30px)]'
    : 'after:basis-[calc(33.333333%_-_30px)]';
  }, [currentPagedArticles]);

  return (
    <PageLayout>
      <SEO title="Blog search result" />
      <BlogCategoryHeader
        className="mt-[132px] px-[22px]"
        title={headerTitle}
        description=""
        articleCount={filteredArticle.length}
      />
      <div className="flex max-w-[1612px] my-[44px] mx-auto md:px-[11px] md:mx-0 md:mt-0 md:mb-[55px] sm:px-[22px]">
        <FollowICash
          className="mr-[73px] md:hidden"
          onFacebookFollow={() => console.log('share article on facebook')}
          onTwitterFollow={() => console.log('share article on twitter')}
          onLinkedInFollow={() => console.log('share article on linkedin')}
        />
        <div className="flex flex-col mr-[153px] md:mr-0 w-full">
          <div className={"flex flex-wrap justify-around mb-[44px] md:mb-[11px] after:content-[''] " + articlesAfterBasis}>
            {currentPagedArticles.length ? currentPagedArticles.map((node: any) => (
              <BlogArticleCard
                key={node.id}
                title={node.h1}
                description={node.metaDescription}
                author={node.author?.name}
                authorImgSrc={node.author?.picture?.file.url || defaultImg}
                date={new Date(node.publicationDate)}
                imgSrc={node.mainImage?.file?.url}
                onClick={() => navigate(`/blog/${node.slug}/`)}
                onAuthorClick={() => navigate(`/blog/${node.author?.slug}/`)}
              >
                <BlogArticleCategories>
                  {node.categories?.map((category: any) => (
                    <BlogArticleCategory
                      key={category?.id}
                      category={category?.name}
                      onClick={() => navigate(`/blog/${category?.slug}/`)}
                    />
                  ))}
                </BlogArticleCategories>
              </BlogArticleCard>
            )) : (
              <div className="max-w-[660px] text-center ml-[-154px] text-[17px] leading-[25px] tracking-[-.3px] text-[#6F7375]
              md:ml-0 md:max-w-[607px] md:text-[15px] md:leading-[20px] md:tracking-[-0.25px]
              sm:text-[13px] sm:leading-[18px] sm:tracking-[-0.15px]">
                <p className="mb-[22px]">Sorry, we couldn't find any article page for that.</p>
                <p>
                  Please try searching with another term to go back to{' '}
                  <a href="/blog" className="global--link">Blog</a>.<br />
                  <a href="/" className="global--link">Click here</a>{' '}
                  to open iCash principal page to view products we offer to get you<br />
                  back on track to financial health anywhere, at any time.
                </p>
              </div>
            )}
          </div>
          {filteredArticle.length > 10 && (
            <div className="mx-auto">
              <Pagination
                total={filteredArticle.length}
                currentPage={currentPage}
                countPerPage={ARTICLES_PER_PAGE}
                onPageNumberClick={(pageNumber: number) => setCurrentPage(pageNumber)}
              />
            </div>
          )}
        </div>
      </div>
      
      <div className="mb-[66px] max-w-[880px] mx-auto px-[22px]">
        <BlogArticleBrowseCategories>
          {allCategories.nodes.map((category: any) => (
            <BlogArticleCategory
              key={category.slug}
              category={category.name}
              hasArrow
              onClick={() => navigate(`/blog/${category.slug}/`)}
            />
          ))}
        </BlogArticleBrowseCategories>
      </div>
      <div className="mb-[44px] mx-auto max-w-[1320px] md:mb-[22px]">
        <BlogSubscribe className="md:mx-[-1rem] md:!w-[calc(100%+2rem)]" handleSubscribe={() => console.log('subscribed!')}>
          <span>{'Subscribe to our '}</span>
          <span className="text-brand-green">newsletter</span>
        </BlogSubscribe>
      </div>
      <div className="mb-[44px]">
        <Products />
      </div>
    </PageLayout>
  );
};

export default BlogSearch;
